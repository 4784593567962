import React, { useEffect, useState, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Button } from "@mui/material";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";

function Ringba() {
  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const navigate = useNavigate();

  const getCallLogs = useCallback(async (start_date, end_date) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/getCalls`;
      const payload = {
        start_date,
        end_date,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(url, payload, config);
      setCalls(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getCallLogs(
      moment(startDate).tz("America/New_York").format("YYYY-MM-DD"),
      moment(endDate).tz("America/New_York").format("YYYY-MM-DD")
    );
  }, [getCallLogs, startDate, endDate]);

  const columns = [
    { field: "ringbaCampaignId", hide: false, width: 150 },
    { field: "campaignName", headerName: "campaignName", width: 250 },
    { field: "inComing", headerName: "inComing", width: 150 },
    { field: "callCompleted", headerName: "callCompleted", width: 150 },
    { field: "live", headerName: "live", width: 150 },
    { field: "duplicate", headerName: "duplicate", width: 150 },
    { field: "blocked", headerName: "blocked", width: 150 },
    { field: "isIncomplete", headerName: "isIncomplete", width: 150 },
    { field: "ended", headerName: "ended", width: 150 },
    { field: "completed", headerName: "completed", width: 150 },
    { field: "profit", headerName: "profit", width: 150 },
    { field: "mbProfit", headerName: "mbProfit", width: 150 },
    { field: "revenue", headerName: "revenue", width: 150 },
    { field: "totalCost", headerName: "totalCost", width: 150 },
  ];

  const rows = calls?.callLogsData || [];
  const formatDate = (date) => {
    return moment(date).tz("America/New_York").format("YYYY-MM-DD");
  };

  const findCall = () => {
    setLoading(true);
    getCallLogs(formatDate(startDate), formatDate(endDate));
  };

  const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];
  return (
    <div className="App" style={{ padding: "10px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "5px",
          border: "solid black 2px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/google-ads")}
      >
        GO TO GOOGLE-ADS
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "5px",
          border: "solid black 2px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/ringba-camp")}
      >
        GO TO RINGBA CAMPAIGN
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <label>start Date : </label>
        <ReactDatePicker
          dateFormat="yyyy-MM-dd"
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
          }}
        />
        <label style={{ margin: "5px" }}>end Date : </label>
        <ReactDatePicker
          dateFormat="yyyy-MM-dd"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
        />
        <Button size="large" style={{ margin: "5px" }} onClick={findCall}>
          FIND
        </Button>
      </div>
      <div style={{ height: 650, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row.ringbaCampaignId}
          loading={loading}
          autoPageSize={true} // default page size
          pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontWeight: "bold",
        }}
      >
        {/* <div>Total calls : {calls?.totalIncoming}</div> */}
        <div>
          Total inComing :{" "}
          {calls?.callLogsCountData &&
            calls.callLogsCountData[0]?.totalIncoming}
        </div>
        <div>
          Total callCompleted :{" "}
          {calls?.callLogsCountData &&
            calls.callLogsCountData[0]?.totalCompleted}
        </div>
        <div>
          Total live :{" "}
          {calls?.callLogsCountData && calls.callLogsCountData[0]?.totalLive}
        </div>
        <div>
          Total duplicate :{" "}
          {calls?.callLogsCountData &&
            calls.callLogsCountData[0]?.totalDuplicate}
        </div>
        <div>
          Total blocked :{" "}
          {calls?.callLogsCountData && calls.callLogsCountData[0]?.totalBlocked}
        </div>
        <div>
          Total isIncomplete:{" "}
          {calls?.callLogsCountData &&
            calls.callLogsCountData[0]?.totalIncomplete}
        </div>
        <div>
          Total Ended :{" "}
          {calls?.callLogsCountData && calls.callLogsCountData[0]?.totalEnded}
        </div>
        <div>
          Total Profit :{" "}
          {calls?.callLogsCountData && calls.callLogsCountData[0]?.totalProfit}
        </div>
        <div>
          Total MB Profit :{" "}
          {calls?.callLogsCountData &&
            calls.callLogsCountData[0]?.totalMbProfit}
        </div>
        <div>
          Total Revenue :{" "}
          {calls?.callLogsCountData && calls.callLogsCountData[0]?.totalRevenue}
        </div>
        <div>
          Total Cost :{" "}
          {calls?.callLogsCountData && calls.callLogsCountData[0]?.totalCost}
        </div>
      </div>
    </div>
  );
}

export default Ringba;
