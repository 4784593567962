import "./App.css";
import GoogleAds from "./GoogleAds";
import Ringba from "./Ringba";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RingbaCampaign from "./RingbaCampain";
import GoogleAdsCount from "./GoogleAdsCount";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Ringba />}></Route>
        <Route path="/google-ads" element={<GoogleAds />}></Route>
        <Route path="/ringba-camp" element={<RingbaCampaign />}></Route>
        <Route path="/google-ads-count" element={<GoogleAdsCount />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
