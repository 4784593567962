import React, { useMemo, useEffect, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import axios from "axios";
import { Button, Typography } from "@mui/material";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import "./App.css";
import { useNavigate } from "react-router-dom";

const GoogleAdsCount = () => {
  const [customers, setCustomers] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const navigate = useNavigate();

  useEffect(() => {
    getCustomers();
  }, []);

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const getCustomers = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/getAdsCount`; // Update the URL to match your API endpoint
    const payload = {
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.post(url, payload, config);
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const findAds = () => {
    getCustomers();
  };

  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "google_customer.descriptiveName",
      },
      {
        header: "Total Clicks",
        accessorKey: "totalClicks",
      },
      {
        header: "Total Video Quartile P100 Rate",
        accessorKey: "totalVideoQuartileP100Rate",
      },
      {
        header: "Total Video Quartile P25 Rate",
        accessorKey: "totalVideoQuartileP25Rate",
      },
      {
        header: "Total Video Views",
        accessorKey: "totalVideoViews",
      },
      {
        header: "Total Conversions",
        accessorKey: "totalConversions",
      },
      {
        header: "Total Cost (Micros)",
        accessorKey: "totalCostMicros",
      },
      {
        header: "Total CTR",
        accessorKey: "totalCtr",
      },
      {
        header: "Total Engagement Rate",
        accessorKey: "totalEngagementRate",
      },
      {
        header: "Total Engagements",
        accessorKey: "totalEngagements",
      },
      {
        header: "Total All Conversions",
        accessorKey: "totalAllConversions",
      },
      {
        header: "Total Average Cost",
        accessorKey: "totalAverageCost",
      },
      {
        header: "Total Average CPC",
        accessorKey: "totalAverageCpc",
      },
      {
        header: "Total Average CPE",
        accessorKey: "totalAverageCpe",
      },
      {
        header: "Total Average CPM",
        accessorKey: "totalAverageCpm",
      },
      {
        header: "Total Average CPV",
        accessorKey: "totalAverageCpv",
      },
      {
        header: "Total Impressions",
        accessorKey: "totalImpressions",
      },
      {
        header: "Total Interaction Rate",
        accessorKey: "totalInteractionRate",
      },
      {
        header: "Total Interactions",
        accessorKey: "totalInteractions",
      },
    ],
    []
  );
  const table = useMaterialReactTable({
    columns,
    data: customers,
    enableExpandAll: false,
    enableExpanding: true,
    filterFromLeafRows: true,

    initialState: { expanded: true },
    paginateExpandedRows: false,
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "5px",
          border: "solid black 2px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/")}
      >
        GO TO RINGBA
      </div>

      <div className="App" style={{ padding: "10px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <label>Start Date : </label>
          <ReactDatePicker
            dateFormat="yyyy-MM-dd"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
          <label style={{ margin: "5px" }}>End Date : </label>
          <ReactDatePicker
            dateFormat="yyyy-MM-dd"
            selected={endDate}
            onChange={(date) => setEndDate(date)}
          />
          <Button size="large" style={{ margin: "5px" }} onClick={findAds}>
            FIND
          </Button>
        </div>
        <div style={{ height: 650, width: "100%" }}>
          <MaterialReactTable table={table} state={{ isLoading: true }} />
        </div>
      </div>
    </>
  );
};

export default GoogleAdsCount;
