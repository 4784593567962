import React, { useEffect, useState, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Button } from "@mui/material";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";

function RingbaCampaign() {
  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const navigate = useNavigate();

  const getCallLogs = useCallback(async (start_date, end_date) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/getCampaignId`;
      const payload = {
        start_date,
        end_date,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(url, payload, config);
      setCalls(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getCallLogs(
      moment(startDate).tz("America/New_York").format("YYYY-MM-DD"),
      moment(endDate).tz("America/New_York").format("YYYY-MM-DD")
    );
  }, [getCallLogs, startDate, endDate]);

  const columns = [
    {
      field: "google_campaign_fk",
      headerName: "Google Campaign Count",
      hide: false,
      width: 300,
    },
    {
      field: "google_campaign_count",
      headerName: "Google Campaign Count",
      width: 300,
    },
  ];

  const rows = calls?.campaingnData || [];
  const formatDate = (date) => {
    return moment(date).tz("America/New_York").format("YYYY-MM-DD");
  };

  const findCall = () => {
    setLoading(true);
    getCallLogs(formatDate(startDate), formatDate(endDate));
  };
  // Assuming rows is the original data array
  const filteredRows = rows.filter(
    (row) =>
      row.google_campaign_fk !== null && row.google_campaign_fk !== undefined
  );

  const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];
  return (
    <div className="App" style={{ padding: "10px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "5px",
          border: "solid black 2px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/google-ads")}
      >
        GO TO GOOGLE-ADS
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <label>start Date : </label>
        <ReactDatePicker
          dateFormat="yyyy-MM-dd"
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
          }}
        />
        <label style={{ margin: "5px" }}>end Date : </label>
        <ReactDatePicker
          dateFormat="yyyy-MM-dd"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
        />
        <Button size="large" style={{ margin: "5px" }} onClick={findCall}>
          FIND
        </Button>
      </div>
      <div style={{ height: 650, width: "100%" }}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          getRowId={(row) => row.google_campaign_fk || row.id || row.index}
          loading={loading}
          autoPageSize={true} // default page size
          pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontWeight: "bold",
        }}
      >
        {/* <div>Total calls : {calls?.totalIncoming}</div> */}
        <div>
          Total Google Campaign :{" "}
          {calls?.campaingnCountData &&
            calls.campaingnCountData[0]?.total_google_campaign}
        </div>
      </div>
    </div>
  );
}

export default RingbaCampaign;
