import React, { useMemo, useEffect, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import axios from "axios";
import { Button, Typography } from "@mui/material";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import "./App.css";
import { useNavigate } from "react-router-dom";

const GoogleAds = () => {
  const [customers, setCustomers] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const navigate = useNavigate();

  useEffect(() => {
    getCustomers();
  }, []);

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const getCustomers = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/getAds`; // Update the URL to match your API endpoint
    const payload = {
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.post(url, payload, config);
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const findAds = () => {
    getCustomers();
  };

  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "descriptiveName",
        enableGrouping: false,
      },
      {
        header: "Campaign name",
        accessorKey: "name",
      },
      {
        header: "Campaign Fk",
        accessorKey: "campaign_fk",
      },
      {
        header: "Clicks",
        accessorKey: "clicks",
        aggregationFn: "sum",
      },
      {
        header: "Video Quartile P100 Rate",
        accessorKey: "videoQuartileP100Rate",
        aggregationFn: "sum",
      },
      {
        header: "Video Quartile P25 Rate",
        accessorKey: "videoQuartileP25Rate",
        aggregationFn: "sum",
      },
      {
        header: "Video Quartile P50 Rate",
        accessorKey: "videoQuartileP50Rate",
        aggregationFn: "sum",
      },
      {
        header: "Video Quartile P75 Rate",
        accessorKey: "videoQuartileP75Rate",
        aggregationFn: "sum",
      },
      {
        header: "Video View Rate",
        accessorKey: "videoViewRate",
        aggregationFn: "sum",
      },
      {
        header: "Video Views",
        accessorKey: "videoViews",
        aggregationFn: "sum",
        header: "Conversions",
        accessorKey: "conversions",
        aggregationFn: "sum",
      },
      {
        header: "Cost (Micros)",
        accessorKey: "costMicros",
        aggregationFn: "sum",
      },
      {
        header: "Cost Per Conversion",
        accessorKey: "costPerConversion",
        aggregationFn: "sum",
      },
      {
        header: "CTR (Click Through Rate)",
        accessorKey: "ctr",
        aggregationFn: "sum",
        header: "Engagement Rate",
        accessorKey: "engagementRate",
        aggregationFn: "sum",
      },
      {
        header: "Engagements",
        accessorKey: "engagements",
        aggregationFn: "sum",
      },
      {
        header: "All Conversions",
        accessorKey: "allConversions",
      },
      {
        header: "Average Cost",
        accessorKey: "averageCost",
      },
      {
        header: "Average CPC",
        accessorKey: "averageCpc",
      },
      {
        header: "Average CPE",
        accessorKey: "averageCpe",
      },
      {
        header: "Average CPM",
        accessorKey: "averageCpm",
      },
      {
        header: "Average CPV",
        accessorKey: "averageCpv",
      },
      {
        header: "Impressions",
        accessorKey: "impressions",
      },
      {
        header: "Interaction Rate",
        accessorKey: "interactionRate",
      },
      {
        header: "Interactions",
        accessorKey: "interactions",
      },
      {
        header: "Customer FK",
        accessorKey: "customer_fk",
      },
      {
        header: "Date",
        accessorKey: "date",
      },
      {
        header: "Hour",
        accessorKey: "hour",
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: customers,
    enableExpandAll: false,
    enableExpanding: true,
    filterFromLeafRows: true,
    enableGrouping: true,
    getSubRows: (row) => {
      const subRows = [];

      if (row.id) {
        const campaigns = row.google_campaigns || [];
        campaigns.forEach((campaign) => {
          subRows.push({
            ...campaign,
            parentCustomerId: row.id,
            isCampaign: true,
          });

          const metrics = campaign.google_campaign_metrics || [];
          metrics.forEach((metric) => {
            subRows.push({
              ...metric,
              parentCampaignId: campaign.id,
              isMetric: true,
            });
          });
        });
      }
      return subRows;
    },
    initialState: { expanded: true, grouping: ["name"] },
    paginateExpandedRows: false,
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "5px",
          border: "solid black 2px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/")}
      >
        GO TO RINGBA
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "5px",
          border: "solid black 2px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/google-ads-count")}
      >
        GO TO GOOGLE COUNT
      </div>
      <div className="App" style={{ padding: "10px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <label>Start Date : </label>
          <ReactDatePicker
            dateFormat="yyyy-MM-dd"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
          <label style={{ margin: "5px" }}>End Date : </label>
          <ReactDatePicker
            dateFormat="yyyy-MM-dd"
            selected={endDate}
            onChange={(date) => setEndDate(date)}
          />
          <Button size="large" style={{ margin: "5px" }} onClick={findAds}>
            FIND
          </Button>
        </div>
        <div style={{ height: 650, width: "100%" }}>
          <MaterialReactTable table={table} state={{ isLoading: true }} />
        </div>
      </div>
    </>
  );
};

export default GoogleAds;
